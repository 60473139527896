import { MotionPlugin } from '@vueuse/motion'
import { defineNuxtPlugin } from '#app'
import defu from 'defu'
import appOptions from './motion.config'

const options = defu(appOptions, {
  "directives": {
    "pop-bottom": {
      "initial": {
        "scale": 0,
        "opacity": 0,
        "y": 100
      },
      "visible": {
        "scale": 1,
        "opacity": 1,
        "y": 0
      }
    }
  }
})

export default defineNuxtPlugin(
  (nuxtApp) => {
    nuxtApp.vueApp.use(MotionPlugin, options)
  }
)
