import { acceptHMRUpdate, defineStore } from 'pinia'
import { useJwt } from '@vueuse/integrations/useJwt'

export const useAuthState = defineStore('authState', {

    state: () => ({
        emailLoginInProgress: false,
        isAuthed: false,
        user: null,
        uid: null,
        email: null,
        profilePhoto: `/images/profile-photo-placeholder.png`,
        defaultProfilePhoto: `/images/profile-photo-placeholder.png`,
        hasChanged: false,
        lastChanged: null,
    }),

    getters: {
        isEmailLoginInProgress: (state) => state.emailLoginInProgress,
        isLoggedIn: (state) => state.isAuthed,
        currentUser: (state) => state.user,
        currentUserUid: (state) => state.uid,
        currentUserProfilePhoto: (state) => state.profilePhoto,
        // Return users current claims
        currentUserClaims: (state) => {
            if (state?.user?.stsTokenManager?.accessToken) {
                const { payload, header } = useJwt(state.user.stsTokenManager.accessToken)
                return { ...payload.value }
            } else {
                return null
            }
        },
        userTenantAdminTenants() {
            return this.currentUserClaims?.tenantadminTenants || []
        },
        userIsTenantAdmin() {
            return this.currentUserClaims?.tenantadmin || false
        },
        userIsPlatformAdmin() {
            return this.currentUserClaims?.platformadmin || false
        },
        userIsTenantUser() {
            return this.currentUserClaims?.tenantuser || false
        },
        userHasVerifiedEmail() {
            return this.currentUserClaims?.email_verified || false
        },
        userPhotoUrl() {
            return this.currentUserClaims?.picture || this.defaultProfilePhoto
        },
        userTenantAdminTotalTenants() {
            return this.currentUserClaims?.tenantadminTenants?.length || 0
        },

    },

    actions: {
        checkLogin() {
            if (!this.user) {
                // console.log(`checkLogin: No user found. Redirecting to login page.`)
                navigateTo('/login')
            }
        },
        doLoginWithEmailPasswordless(email) {
            console.log('Starting passwordless email login...', email)
            this.emailLoginInProgress = true
            const { $sendEmailSignInLink } = useNuxtApp()
            return $sendEmailSignInLink({ email })
        },
        cancelLoginWithEmailPasswordless() {
            console.log('Cancelling passwordless email login...')
            this.emailLoginInProgress = false
        },
        userLoggedIn(user) {
            // console.log('store/auth - User logged in', user)
            if (user) {
                this.isAuthed = true
                this.user = user
                this.uid = user.uid
                this.email = user.email || null
                this.profilePhoto = user.photoURL || `/images/profile-photo-placeholder.png`
            }
            this.user = user
            if (!this.hasChanged) {
                // this.$nuxt.$router.push('/account')
            }
            this.hasChanged = true
            this.lastChanged = new Date().toISOString()
        },
        userLoggedOut(user) {
            if (!user) return // console.log('userLoggedOut - No user, not doing anything')
            // console.log('User logged out, clearing state at userLoggedOut()')
            // TODO: Prevent redirect if user hasn't been logged in before
            // this.$nuxt.$router.push('/')
            this.$reset()
        },

    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthState, import.meta.hot))
}
