import { defineNuxtPlugin, useRuntimeConfig } from "#app";
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  // Get config from nuxtApp to use dynamic config
  const config = useRuntimeConfig();

  // Get router instance
  const router = useRouter();

  // Get vueApp instance
  const { vueApp } = nuxtApp;

  if (config.public["SENTRY_ENABLED"]) {
    console.log(`Sentry enabled`);
  } else {
    return console.log(`Sentry disabled`);
  }

  // Initialise Sentry
  Sentry.init({
    app: [vueApp],
    dsn: config.SENTRY_DSN,
    release: config.RELEASE_SHA || "unknown", // Enable debug mode
    integrations: [
      // Tracing
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/wetix\.net\/api/,
          "wetix.net",
          "api.wetix.net",
          "gowetix.com",
          "api.gowetix.com",
          /^\//,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      // Sentry Replays
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        // maskAllText: true,
        // blockAllMedia: true,
      }),
    ],
    logErrors: false, // Note that this doesn't seem to work with nuxt 3
    // Traces config
    tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE || 1.0, // Sentry recommends adjusting this value in production
    // Replays config
    replaysSessionSampleRate: config.SENTRY_REPLAY_SESSION_SAMPLE_RATE || 1.0, // Sentry recommends adjusting this value in production
    replaysOnErrorSampleRate: config.SENTRY_REPLAY_ERROR_SAMPLE_RATE || 1.0, // Sentry recommends adjusting this value in production
    // Vue-specific features
    trackComponents: true, // Enable tracking of Vue components
    hooks: ["activate", "create", "destroy", "mount", "update"], // Enable hooks for Vue components
    // Other config
    debug: config.SENTRY_ENABLE_DEBUG || false, // Enable debug mode
    environment: config.public["ENVIRONMENT"] || "dev", // Set environment
    // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        );
      }
      // Continue sending to Sentry
      return event;
    },
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ["activate", "mount", "update"],
    })
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "mount", "update"],
  });

  return {
    provide: {
      sentrySetContext: (n, context) => Sentry.setContext(n, context),
      sentrySetUser: (user) => Sentry.setUser(user),
      sentrySetTag: (tagName, value) => Sentry.setTag(tagName, value),
      sentryAddBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
    },
  };
});
