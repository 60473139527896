
import { defineNuxtPlugin, useNuxtApp } from '#app'

export default defineNuxtPlugin(() => {
    return {
        provide: {
            // Fetches a random image from Unsplash
            unsplashSrcRandom: async ({ query, size = 'regular' }) => {
                const photoObj: any = await useFetch('/api/unsplash-proxy/random', { params: { query } })
                // console.log('Got unsplash image: ', photoObj)
                const result = JSON.parse(photoObj)
                return result
            },

        }
    }
})

