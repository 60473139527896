export default defineAppConfig({
    // Config for NuxtIcon to make icon sizes match the old HeroIcon ones.
    nuxtIcon: {
        size: '16px', // default <Icon> size applied
        class: 'icon', // default <Icon> class applied
        aliases: {
            'nuxt': 'logos:nuxt-icon',
            'wetix': 'WetixSymbolIconColor',
            'wetix:light': 'WetixSymbolIconLight',
            'wetix:dark': 'WetixSymbolIconDark',
        }
    }
})