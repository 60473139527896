import { dinero, add, down, toDecimal, multiply, toSnapshot } from "dinero.js";
import { GBP, USD } from "@dinero.js/currencies";

export default defineNuxtPlugin(() => {
  return {
    provide: {
      addCurrency: (currencySnap1, currencySnap2) => {
        const d1 = dinero(currencySnap1);
        const d2 = dinero(currencySnap2);

        return toSnapshot(add(d1, d2));
      },
      // Takes a Dinero currency snapshot object and returns it as a string eg £0.40
      displayCurrency: (currencyObj) => {
        if (!currencyObj) return 0;
        const d = dinero(currencyObj);
        // return toFormat(d, ({ amount, currency }) => `£${amount.toFixed(currency.exponent)}`)
        const decimal = toDecimal(d);
        // return `${toSnapshot(d).currency.code} ${decimal}`;
        // Convert decimal from a string into a number
        const numberVal = Number(decimal);
        return `${
          toSnapshot(d).currency.code === "USD" ? "$" : "£"
        }${numberVal.toFixed(2)}`;
      },
      displayCurrencyNumber: (currencyObj) => {
        if (!currencyObj) return 0;
        const d = dinero(currencyObj);
        // return toFormat(d, ({ amount, currency }) => `${amount.toFixed(currency.exponent)}`)
        const decimal = toDecimal(d);
        const numberVal = Number(decimal);
        return numberVal.toFixed(2);
      },
      displayCurrencyInternational: (currencyObj) => {
        if (!currencyObj) return 0;
        const d = dinero(currencyObj);
        // return toFormat(d, ({ amount, currency }) => `${currency.code} ${amount.toFixed(currency.exponent)}`)
        const decimal = toDecimal(d);
        return `${toSnapshot(d).currency.code} ${decimal}`;
      },
      displayCurrencyForAnalytics: (currencyObj) => {
        if (!currencyObj) return { currency: "GBP", value: 0.0 };
        const d = dinero(currencyObj);
        // const currency = toFormat(d, ({ amount, currency }) => `${currency.code}`)
        const currency = `${toSnapshot(d).currency.code}`;
        const value = toDecimal(d);
        return {
          currency,
          value,
        };
      },
      getItemValueForAnalytics: (item) => {
        console.log(`Calculating value for item: `, item);
        const { fee, price } = item;
        const dFee = dinero(fee);
        const dPrice = dinero(price);
        const dValue = add(dFee, dPrice);
        console.log(`Total: `, dValue);
        if (!dValue) return { currency: "GBP", value: 0.0 };
        // const currency = toFormat(dValue, ({ amount, currency }) => `${currency.code}`)
        const currency = `${toSnapshot(dValue).currency.code}`;
        // const value = toUnit(dValue, { digits: 2, round: down })
        const value = toDecimal(dValue);
        return {
          currency,
          value,
        };
      },
      displayCurrencyFromStripeMoney: (amount: number, tcurrency: string) => {
        if (!amount) return 0;
        const currency = tcurrency === "USD" ? USD : GBP;
        const d = dinero({ amount, currency, scale: 2 });
        // return toFormat(d, ({ amount, currency }) => `£${amount.toFixed(currency.exponent)}`)
        const decimal = toDecimal(d);
        // return `${toSnapshot(d).currency.code} ${decimal}`;
        // Convert decimal from a string into a number
        const numberVal = Number(decimal);
        return `${
          toSnapshot(d).currency.code === "USD" ? "$" : "£"
        }${numberVal.toFixed(2)}`;
      },
    },
  };
});
