
import { defuFn } from '/opt/build/repo/frontend/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyCVy3MK918X1C_iRuCHhTCR1inXjqZwvYs",
    "authDomain": "wetix-production.firebaseapp.com",
    "projectId": "wetix-production",
    "appId": "1:506672660631:web:cd428e3023b8045f39a9d2",
    "measurementId": "G-3V4DPBY0RC",
    "databaseURL": "",
    "storageBucket": "wetix-production.appspot.com"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "auth": true,
    "config": {
      "apiKey": "AIzaSyCVy3MK918X1C_iRuCHhTCR1inXjqZwvYs",
      "authDomain": "wetix-production.firebaseapp.com",
      "projectId": "wetix-production",
      "appId": "1:506672660631:web:cd428e3023b8045f39a9d2",
      "measurementId": "G-3V4DPBY0RC",
      "databaseURL": "",
      "storageBucket": "wetix-production.appspot.com"
    }
  }
}

import cfg0 from "/opt/build/repo/frontend/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
