import { defineAsyncComponent } from 'vue'
export default {
  "access-control": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/access-control.vue").then(m => m.default || m)),
  "account-tiered-sidebar": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/account-tiered-sidebar.vue").then(m => m.default || m)),
  account: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/account.vue").then(m => m.default || m)),
  "app-communication-topbar": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/app-communication-topbar.vue").then(m => m.default || m)),
  authentication: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/authentication.vue").then(m => m.default || m)),
  basic: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/basic.vue").then(m => m.default || m)),
  blank: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/blank.vue").then(m => m.default || m)),
  "customer-account": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/customer-account.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/default.vue").then(m => m.default || m)),
  error: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/error.vue").then(m => m.default || m)),
  fullheight: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/fullheight.vue").then(m => m.default || m)),
  "h-fullscreen": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/h-fullscreen.vue").then(m => m.default || m)),
  order: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/order.vue").then(m => m.default || m)),
  "scanner-basic": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/scanner-basic.vue").then(m => m.default || m)),
  sitedefault: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/sitedefault.vue").then(m => m.default || m)),
  "tenant-admin-topbar": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/tenant-admin-topbar.vue").then(m => m.default || m)),
  "tenant-admin": defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/tenant-admin.vue").then(m => m.default || m)),
  user: defineAsyncComponent(() => import("/opt/build/repo/frontend/layouts/user.vue").then(m => m.default || m))
}