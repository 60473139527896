<template>
    <div>
        <div class="min-h-full pt-16 pb-12 flex flex-col bg-white">
            <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div class="flex-shrink-0 flex justify-center">
                    <a href="/" class="inline-flex">
                        <span class="sr-only">WeTix</span>
                        <img class="h-12 w-auto" src="/images/wetix-logo.png" alt="Wetix" />
                    </a>
                </div>
                <div class="py-16">
                    <div class="text-center">
                        <p class="text-sm font-semibold text-primary-600 uppercase tracking-wide">Error {{ error.statusCode
                        }}</p>
                        <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{{
                            error.statusMessage || 'Oops!' }}</h1>
                        <p class="mt-2 text-base text-gray-500">Sorry, something went wrong.</p>
                        <p class="mt-2 text-base text-gray-400">{{ error?.message }}</p>
                        <pre class="mt-4 mb-4">{{ error }}</pre>
                        <button @click="handleError" class="text-base font-medium text-primary-600 hover:text-primary-500">
                            <span aria-hidden="true">&larr;</span>
                            Go back
                        </button>
                        <!-- <div class="mt-6">
                            <a
                                @click="handleError"
                                class="text-base font-medium text-primary-600 hover:text-primary-500"
                            >Go back home</a>
                        </div>-->
                    </div>
                </div>
            </main>
            <footer class="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <nav class="flex justify-center space-x-4">
                    <a href="https://support.wetix.events"
                        class="text-sm font-medium text-gray-500 hover:text-gray-600">Contact Support</a>
                    <span class="inline-block border-l border-gray-300" aria-hidden="true" />
                    <a href="https://status.wetix.run/"
                        class="text-sm font-medium text-gray-500 hover:text-gray-600">Service Status</a>
                    <!-- <span class="inline-block border-l border-gray-300" aria-hidden="true" />
                    <a
                        href="#"
                        class="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >Twitter</a>-->
                </nav>
            </footer>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    error: Object
})

const handleError = () => {
    // Go back 
    history.back()
    // clearError({ redirect: '/' })
}
</script>