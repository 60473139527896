import { useNotificationState } from "~/stores/notifications";

export type TypeNotification = {
  id?: string;
  type?: string;
  message?: string;
  title?: string;
  persistent?: boolean;
  timeout?: number;
  duration?: number;
};

export default defineNuxtPlugin((nuxtApp) => {
  const notificationState = useNotificationState();

  return {
    provide: {
      notify: (n: TypeNotification) => {
        notificationState.addNotification(n as TypeNotification);
      },
    },
  };
});
