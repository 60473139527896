import md5Hex from 'md5-hex';

export default defineNuxtPlugin(() => {
    return {
        provide: {
            // Returns URL for gravatar or a placeholder. 
            // Eg: gravatarUrl('me@jontyb.co.uk', {size: 200});
            userAvatar: (identifier: string, options: any) => {

                const placeholderUrl = `/images/placeholders/user-avatar-placeholder-blue.png`

                if (!identifier) {
                    return placeholderUrl
                }

                if (identifier.includes('@')) {
                    identifier = identifier.toLowerCase().trim();
                }

                const baseUrl = new URL('https://gravatar.com/avatar/');
                baseUrl.pathname += md5Hex(identifier);
                baseUrl.search = new URLSearchParams(options).toString()

                return baseUrl.toString();
            }
        }
    }
})